import MainImg from "../images/chooseUs/main.png";
import Box1 from "../images/chooseUs/icon1.png";
import Box2 from "../images/chooseUs/icon2.png";
import Box3 from "../images/chooseUs/icon3.png";
import Box4 from "../images/chooseUs/icon4.png";

function ChooseUs() {
  return (
    <>
      <section className="choose-section">
        <div className="container">
          <div className="choose-container">
            <img
              className="choose-container__img"
              src={MainImg}
              alt="car_img"
            />
            <div className="text-container">
              <div className="text-container__left">
                <h4>Choosing Us Is Wise</h4>
                <h2>Best value to money deals to cater your trip</h2>
                <p>
                Embark on a seamless car rental experience in the Coral Coast, Fiji. Unlock unbeatable deals and discounts on long term rentals applicable on all of our fleet of reliable vehicles, catering to your diverse travel needs. Our commitment to affordability ensures you enjoy top-quality services and products without exceeding your budget. Take the wheel and explore the beauty of the Coral Coast, Fiji with our specially tailored car rental deals, providing you with the freedom to travel at your own pace. Don't miss out on the chance to save big on your next rental; we are your go-to destination for hassle-free and budget-friendly car rentals in the Coral Coast, Fiji. Secure your ride today and make the most of your journey.
                </p>

                <h4>Requirements & Terms</h4>
                <p>
                We are 100% Locally Owned business, and we can work according to your requirements.
We will need your driver's license picture & flight details to confirm your booking.
We will assist you in any way possible.
Hire is restricted to mainland usage only (Viti Levu)
We take all major debit/credit cards
You can pay us whenever you are ready.
Also, you can pay us via online banking, TT, Western Union, MoneyGram or others if it is easier/cheaper for you.
                </p>
               
              </div>
              <div className="text-container__right">
                <div className="text-container__right__box">
                  <img src={Box1} alt="car-img" />
                  <div className="text-container__right__box__text">
                    <h4>All Vehicle Types</h4>
                    <p>
                     With all variety of vehicle types, take your driving experience to next level.
                    </p>
                  </div>
                </div>


                
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box2} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>All Inclusive Pricing</h4>
                    <p>
                      Get everything you need in one convenient, transparent
                      price with our all-inclusive pricing policy.
                    </p>
                  </div>
                </div>


                <div className="text-container__right__box">
                  {" "}
                  <img src={Box4} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>Road Side Assistance</h4>
                    <p>
                    We provide prompt roadside assistance to our customers in need.
                    </p>
                  </div>
                </div>




                <div className="text-container__right__box">
                  {" "}
                  <img src={Box3} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>Quick Customer Support</h4>
                    <p>
                     We love our customers, get lightening support from our customer support.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChooseUs;
