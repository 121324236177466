
import BgShape from "../images/hero/bookbg1.jpg";
function Banner() {


  const bgImageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };


  return (
    <>
      <section
        className="banner-section"
        style={{
         // backgroundColor: "#00b712",
          //backgroundImage: "linear-gradient(275deg, #00690a 0%, #3a9e0e 79%)"
          position: 'relative'
        }}
      >
  <img src={BgShape} alt="Background Shape" style={bgImageStyle} />

        <div className="container">
          <div className="row">
            <div className="col-12" style={{ height: "220px" }}>
              <div className="banner-content">
                <div className="banner-content__text" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.29)' , width:'100%'
              }}  >
                  <h2>Best Car Rental In Coral Coast, Fiji!</h2>
                  <p style={{color:'white'}}>
                    Also Book your car at{" "}
                    <span style={{ color: "yellow" }}>+679 934 1249  by calling or whatsapp message</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="col-12" style={{ height: "270px" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7977.37672705176!2d177.5298759!3d-18.1678052!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6e10a9539250af4b%3A0xdd2c23c4c22b8816!2sGreen%20Rental%20Cars%20FIJI!5e0!3m2!1sen!2stt!4v1632176409743!5m2!1sen!2stt"
                width="100%"
                height="100%"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="eager"
              ></iframe>       
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
