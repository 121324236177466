

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage'
import 'firebase/compat/firestore';

const firebaseConfig = {

    apiKey: "AIzaSyA1a5TkIx7yn0h_ClrkbWkQ02erZFSljdw",

    authDomain: "greenrentalcars-9cd02.firebaseapp.com",
  
    projectId: "greenrentalcars-9cd02",
  
    storageBucket: "greenrentalcars-9cd02.appspot.com",
  
    messagingSenderId: "222504897272",
  
    appId: "1:222504897272:web:887248929262da5774090d"
  
  
  };
  
  
  
let app;

if (firebase.apps.length === 0) {
    app = firebase.initializeApp(firebaseConfig)
} else {
    app = firebase.app();
}

const db = app.firestore();


export {db} 