
function Footer() {
  function handleSubscribe() {
    alert("Thanks for subscribing!!!");
  }

  return (
    <>
      <footer style={{height:'120px', marginTop:'-90px'}}>
        <div className="container" >
          <div className="footer-content">
            <ul className="footer-content__2">
              <li>Contact Us</li>

              <li style={{ fontSize: "14px", fontWeight: "bold" }}>
                <a href="tel:+679 934 1249">&#x260E; +679 934 1249</a>
              </li>

             
              <li style={{ fontSize: "14px", fontWeight: "bold" }}>
                <a href="tel:+679 9008810">&#x260E;  +679 9008810</a>
              </li>


              <li style={{ fontSize: "14px", fontWeight: "bold" }}>
                <a
                  href="mailto: 
                  greenrentalcars@gmail.com"
                >
                  &#9993; greenrentalcars@gmail.com
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://maps.app.goo.gl/gyo3oLjVkVEJomSi8"
                  // href="https://jephunneh.com/"
                >
                  &#128205; Lot 2 Muasara Sigatoka ,Fiji
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px", fontWeight: "bold" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://maps.app.goo.gl/gyo3oLjVkVEJomSi8"
                  // href="https://jephunneh.com/"
                >
                  📮 PO Box 2004, Sigatoka
                </a>
              </li>
            </ul>

            <ul className="footer-content__1">
              <li>
                <span style={{ color: "green" }}>Green Rental Cars</span>
               
              </li>

              <li style={{ fontSize: "13px" }}>
                We offer a range of vehicles for car rental in Fiji.
                To rent a car in Fiji, we're the best choice.
              </li>

              <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://dev2code.com"
                >
                  <span>Website By:</span>
                </a>
              </li>

              <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://web4business.tech"
                >
                  <span style={{ color: "green" }}>Qasim</span>
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Company</li>
              <li>
                <a href="https://maps.app.goo.gl/gyo3oLjVkVEJomSi8">
                  Our Location
                </a>
              </li>
              <li>
                <a href="/">Careers</a>
              </li>
              <li>
                <a href="/">Mobile</a>
              </li>

              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://admingreenrentalcars.netlify.app"
                  //
                >
                  Admin &#128187;
                </a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Working Hours</li>
              <li>Mon - Sat: 8:00AM - 5:00PM</li>
              <li>Sun: Closed</li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
