import React, { useState, useEffect } from "react";

function Bannerz() {
  

  const images = [
    {
      url: "https://dsvsbigncb06y.cloudfront.net/site/cruise/fiji/cruise--fiji-yasawa-islands-xl.jpg",
    },


    {
      url: "https://interactives.stuff.co.nz/travel/fiji/assets/2I81s91Y8k/main-header-v1-frame-0ms-1920x1080.jpg",
    },
    {
      url: "https://media.nomadicmatt.com/2023/fijiguide1.jpg",
    },


    {
      url: "https://miro.medium.com/v2/resize:fit:1000/1*ezJ-HCZ8zS80tpWJbfN1Qg.jpeg",
    },

    {
      url: "https://imgs.mongabay.com/wp-content/uploads/sites/20/2018/01/01201727/6.-Kampung-Nechiebe-berada-di-pesisir-samudera-pasifik-dan-di-kaki-gunung-Cyclops.-Foto-by-drone-Infist-jpg-1200x719.jpg",
    },
    
    
    {
      url: "https://going-cms-strapi.s3.us-east-1.amazonaws.com/web_1920x1200_ed09865dc9.webp",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  const bgImageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };

  return (
    <>
      <section
        className="banner-section"
        style={{
          //backgroundColor: '#00b712',
         // backgroundImage: 'linear-gradient(95deg, #00690a 0%, #3a9e0e 79%)'
         position: 'relative',
         height:'600px'
        }}
      >
         <img alt="Background Shape" style={bgImageStyle}  src={images[currentIndex].url}
            />
      

  
    </section>
  </>
);
}

export default Bannerz;