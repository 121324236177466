import Img2 from "../images/testimonials/pfp1.jpg";
import Img3 from "../images/testimonials/pfp2.jpg";

function Testimonials() {
  return (
    <>
      <section className="testimonials-section">
        <div className="container">
          <div className="testimonials-content">
            <div className="testimonials-content__title">
              <h4>Reviewed by People</h4>
              <h2>Client's Testimonials</h2>
              <p>
              Discover the positive impact through client testimonials. Our reliable and affordable car rentals in Sigatoka, Fiji have earned rave reviews. Join satisfied customers for a seamless and memorable travel experience. Choose Us for your next journey.
              </p>
            </div>

            <div className="all-testimonials">
              <div className="all-testimonials__box">
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p>
                "Awesome customer experience! Hassle free, Mr Green delivered our car to our resort and then picked it up at the end of our stay! Mr Green came to us and made it easy, they were so friendly and helpful! "
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <img src={Img2} alt="user_img" />
                    <span>
                    <h4>Alan Vestal</h4>
                      <p>USA</p>
                    </span>
                  </div>
                </div>
              </div>

              <div className="all-testimonials__box box-2">
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p>
                  "Excellent service,  brilliant communication. Very flexible as we had to change our dates at literally the last minute. The car was great, spotless, efficient. Great price.
I would highly recommend this car rental company."
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <img src={Img3} alt="user_img" />
                    <span>
                    <h4>Possum Jack</h4>
                      <p>Canada</p>
                    </span>
                  </div>
                </div>
              </div>



              
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
