import { useState } from "react";
import Card1 from "../images/about/pay2.png";
import Card2 from "../images/about/pay1.png";

function Faq() {
  const [activeQ, setActiveQ] = useState("q1");

  const openQ = (id) => {
    setActiveQ(activeQ === id ? "" : id);
  };

  const getClassAnswer = (id) => {
    return activeQ === id ? "active-answer" : "";
  };

  const getClassQuestion = (id) => {
    return activeQ === id ? "active-question" : "";
  };

  return (
    <>
      <section className="faq-section" style={{marginTop:'-150px'}}>
        <div className="container" >
          <div className="faq-content">
            <div className="faq-content__title">
              <h5>FAQ</h5>
              <h2>Frequently Asked Questions</h2>
              <p>
                Frequently Asked Questions About the Car Rental Booking Process
                on Our Website: Answers to Common Concerns and Inquiries.
              </p>
            </div>

            <div className="all-questions">
              <div className="faq-box">
                <div
                  style={{
                    backgroundColor: "#00b712",
                    backgroundImage:
                      "linear-gradient(275deg, #00690a 0%, #3a9e0e 79%)",
                  }}
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__question  ${getClassQuestion("q1")}`}
                >
                  <p>1. Do you provide rental and leasing facility both?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__answer ${getClassAnswer("q1")}`}
                >
                  Yes, we arrange short term, long term and lease hires. For rental hires simply fill in the booking form above and we will get back to you via email. For lease hire email us, or get in touch via call, whatsapp or viber.
                </div>
              </div>
              <div className="faq-box">
                <div
                  style={{
                    backgroundColor: "#00b712",
                    backgroundImage:
                      "linear-gradient(315deg, #00990e 0%, #4dd412 74%)",
                  }}
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__question ${getClassQuestion("q2")}`}
                >
                  <p>2. How can I contact you?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__answer ${getClassAnswer("q2")}`}
                >
                  We can be contacted via whatsapp, viber, email or even a txt
                  message. Discover the perfect car rental deals in Coral Coast,
                  Fiji.
                </div>
              </div>
              <div className="faq-box">
                <div
                  style={{
                    backgroundColor: "#00b712",
                    backgroundImage:
                      "linear-gradient(275deg, #00690a 0%, #3a9e0e 79%)",
                  }}
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__question ${getClassQuestion("q3")}`}
                >
                  <p>3. When was this company established?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__answer ${getClassAnswer("q3")}`}
                >
                  Green Rental Cars was establed in 2013 and is since servicing
                  customers in Coral Coast, Fiji with professionalism.
                </div>
              </div>
              <div className="faq-box">
                <div
                  style={{
                    backgroundColor: "#00b712",
                    backgroundImage:
                      "linear-gradient(315deg, #00990e 0%, #4dd412 74%)",
                  }}
                  id="q3"
                  onClick={() => openQ("q4")}
                  className={`faq-box__question ${getClassQuestion("q4")}`}
                >
                  <p>4. What are the payment options?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>

                <div
                  id="q4"
                  onClick={() => openQ("q4")}
                  className={`faq-box__answer ${getClassAnswer("q4")}`}
                >
                  Cash, credit /debit card, TT, online banking and western union
                </div>
              </div>

              <div className="faq-box">
                <div
                  style={{
                    backgroundColor: "#00b712",
                    backgroundImage:
                      "linear-gradient(275deg, #00690a 0%, #3a9e0e 79%)",
                  }}
                  id="q5"
                  onClick={() => openQ("q5")}
                  className={`faq-box__question ${getClassQuestion("q5")}`}
                >
                  <p>5. Do you pick up passengers or transfer luggage?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q5"
                  onClick={() => openQ("q5")}
                  className={`faq-box__answer ${getClassAnswer("q5")}`}
                >
                 Although we are based at Coral Coast, we can deliver or pick up passengers, luggage or items throughout the main land (Viti Levu) at reasonable costs. We can also arrange your tours and airport transfers
                </div>
              </div>

              <div className="faq-box">
                <div
                  style={{
                    backgroundColor: "#00b712",
                    backgroundImage:
                      "linear-gradient(315deg, #00990e 0%, #4dd412 74%)",
                  }}
                  id="q6"
                  onClick={() => openQ("q6")}
                  className={`faq-box__question ${getClassQuestion("q6")}`}
                >
                  <p>6. Can you recommend places to visit nearby? Do you also arrange tours?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q6"
                  onClick={() => openQ("q6")}
                  className={`faq-box__answer ${getClassAnswer("q6")}`}
                >
                Contact us for quotes or simply for any tourist information for free. This includes recommended places to visit during your Fiji stay. We Can also arrange your tours and airport transfers.
                </div>
              </div>

              <div className="faq-box">
                <div
                  style={{
                    backgroundColor: "#00b712",
                    backgroundImage:
                      "linear-gradient(275deg, #00690a 0%, #3a9e0e 79%)",
                  }}
                  id="q7"
                  onClick={() => openQ("q7")}
                  className={`faq-box__question ${getClassQuestion("q7")}`}
                >
                  <p>7. Is it worth it to rent a car?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q7"
                  onClick={() => openQ("q7")}
                  className={`faq-box__answer ${getClassAnswer("q7")}`}
                >
Travelers will find several advantages to renting a car, including having an easy way to travel that's faster than public transportation and the ability to feel more like a local. This also comes in handy when you're in a place that doesn't have that many taxis or you plan on traveling a lot to the point where taxis would be too expensive or impractical. Planning your vacation itinerary is easier since your transportation is taken care of in advance, rather than having to factor for bus routes. This is a stress-free way to get from place to place with your loved ones.                </div>
              </div>







            </div>
          </div>

          <div style={{ marginTop: "35px" }}>
            <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
              We Accept All Payment Methods
            </h2>
            <div className="row" style={{marginTop:'7px'}}>
              <div className="col-6">
                <img src={Card1} style={{width:'90%', height:'100%'}} />
              </div>

              <div className="col-6">
                <img src={Card2}  style={{width:'90%', height:'100%'}} />
              </div>
            </div>
          </div>


        </div>
      </section>
    </>
  );
}

export default Faq;
